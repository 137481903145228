<script setup lang="ts">
import TheHeader from '@/Components/TheHeader.vue'
import DefaultLayout from '@/Layouts/DefaultLayout.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { showLocationAndScrollTop } from '@/Composables/use-scroll-&-open-search'
import { onMounted, ref } from 'vue'
import TheArticlesByCategoriesItem from '@/Pages/the-articles/components/TheArticlesByCategoriesItem.vue'
import { Article } from '@/Pages/error-pages/interfaces/errorPage'
import { Head } from '@inertiajs/vue3'

defineProps<{
    statusCode: number
}>()

const staticArticles = ref<Article[]>([])

onMounted(() => {
    staticArticles.value = [
        {
            'id': '207',
            'url': route('articles', 'staying-in-hostels-tips'),
            'title': 'First-Time in a Hostel? 17 Basic & Advanced Tips to have a great Time',
            'thumbnail': '/pics/articles/originals/26/5039926.jpg',
            'snippet': 'Sleeping and staying in hostels can be challenging. Between having to share with people you don\'t necessarily know, ',
            'isForLoggedIn': false,
            'isShowFull': true
        },

        {
            'id': '3',
            'url': route('articles', 'what-to-pack'),
            'title': 'Packing Guide for Hostel Backpackers',
            'thumbnail': '/pics/articles/originals/20/5039920.jpg',
            'snippet': 'Those of us here at Hostelz.com have done a lot of travelling and we have a lot of opinions about the best stuff to ',
            'isForLoggedIn': false,
            'isShowFull': true
        },

        {
            'id': '15',
            'url': route('articles', 'why-hostel-life-is-better-backpacking'),
            'title': 'Why Hostel Life is Better than Real Life (8 Solid Reasons)',
            'thumbnail': '/pics/articles/originals/19/5039919.jpg',
            'snippet': 'Why hostel? Well, as times move on, so does the accommodation world. These days, hostels often offer a much better ',
            'isForLoggedIn': false,
            'isShowFull': true
        }
    ]
})
</script>

<template>
    <DefaultLayout is-hide-signup>
        <Head>
            <title>Oops, looks like this page took an unexpected detour!</title>
        </Head>
        <template #header>
            <TheHeader
                with-search
            />
        </template>

        <section class="pb-5 pb-sm-5 pb-md-6 pt-5 pt-md-6 bg-gray-100">
            <div class="container">
                <div class="text-center justify-content-around">
                    <div class="col-lg-12">
                        <div v-if="statusCode === 404">
                            <h1 class="hero-heading h2">
                                Oops, looks like this page took an unexpected detour!
                            </h1>
                        </div>

                        <div v-else>
                            <h1 class="hero-heading h2">
                                <FontAwesomeIcon class="fa fa-exclamation-circle " :icon="faExclamationCircle" />
                                Sorry an error occurred...
                            </h1>
                            <p>
                                The error has been logged and we will look into it.
                            </p>
                        </div>

                        <p>
                            Please use our powerful hostel search engine. It will bring you to any destination you want.
                        </p>

                        <a
                            :href="route('home')"
                            class="btn btn-danger mt-2 mt-sm-0 text-nowrap"
                        >
                            <FontAwesomeIcon class="fa fa-search mr-1 mr-md-3 search-icon--ssr" :icon="faSearch" />

                            Find your next hostel
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-4 py-sm-5">
            <div class="container">
                <div class="mb-4">
                    <h3>
                        Here are more helpful Guides for You:
                    </h3>
                </div>
                <div class="row mb-5">
                    <TheArticlesByCategoriesItem
                        v-for="(article, key) in staticArticles"
                        :key
                        :article="article"
                    />
                </div>
            </div>
        </section>
    </DefaultLayout>
</template>

<style scoped lang="scss">
:deep(.header-line) {
    height: 0;
}

.search-icon--ssr {
    width: 16px;
    height: 16px;
}
</style>